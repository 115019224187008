// @autogenerated


export default [
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport Alert from \"@govtnz/ds/build/mustache/Alert.mustache\";\nimport H2 from \"@govtnz/ds/build/mustache/H2.mustache\";\nimport P from \"@govtnz/ds/build/mustache/P.mustache\";\n\n// The variables Alert, H2, P are all strings that are mustache templates.\n\nexport default `${Mustache.render(Alert, {\n  level: `info`,\n  headingId: `heading1`,\n  children: `${Mustache.render(H2, {\n    id: `heading1`,\n    children: `Note: Nominations are currently closed`\n  })}${Mustache.render(P, {\n    marginBottom0: ``,\n    children: `You can nominate a new provider at the start of the next financial year.`\n  })}`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-js/Alert\";\nimport \"@govtnz/ds/build/css/Alert.css\"; // or @govtnz/ds/build/scss/Alert.scss\nimport H2 from \"@govtnz/ds/build/react-js/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport P from \"@govtnz/ds/build/react-js/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <Alert level=\"info\" headingId=\"heading1\">\n    <H2 id=\"heading1\">Note: Nominations are currently closed</H2>\n    <P marginBottom0>\n      You can nominate a new provider at the start of the next financial year.\n    </P>\n  </Alert>\n);\n",
    "react-ts": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-ts/Alert\";\nimport \"@govtnz/ds/build/css/Alert.css\"; // or @govtnz/ds/build/scss/Alert.scss\nimport H2 from \"@govtnz/ds/build/react-ts/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport P from \"@govtnz/ds/build/react-ts/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <Alert level=\"info\" headingId=\"heading1\">\n    <H2 id=\"heading1\">Note: Nominations are currently closed</H2>\n    <P marginBottom0>\n      You can nominate a new provider at the start of the next financial year.\n    </P>\n  </Alert>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-js-styled-components/Alert\";\nimport H2 from \"@govtnz/ds/build/react-js-styled-components/H2\";\nimport P from \"@govtnz/ds/build/react-js-styled-components/P\";\n\nexport default () => (\n  <Alert level=\"info\" headingId=\"heading1\">\n    <H2 id=\"heading1\">Note: Nominations are currently closed</H2>\n    <P marginBottom0>\n      You can nominate a new provider at the start of the next financial year.\n    </P>\n  </Alert>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-ts-styled-components/Alert\";\nimport H2 from \"@govtnz/ds/build/react-ts-styled-components/H2\";\nimport P from \"@govtnz/ds/build/react-ts-styled-components/P\";\n\nexport default () => (\n  <Alert level=\"info\" headingId=\"heading1\">\n    <H2 id=\"heading1\">Note: Nominations are currently closed</H2>\n    <P marginBottom0>\n      You can nominate a new provider at the start of the next financial year.\n    </P>\n  </Alert>\n);\n",
    "vue-js": "<template>\n  <c-alert level=\"info\" headingId=\"heading1\">\n    <c-h2 id=\"heading1\">\n      Note: Nominations are currently closed\n    </c-h2>\n    <c-p marginBottom0>\n      You can nominate a new provider at the start of the next financial year.\n    </c-p>\n  </c-alert>\n</template>\n<script>\nimport Vue from \"vue\";\nimport Alert from \"@govtnz/ds/build/vue-js/Alert.vue\";\nimport H2 from \"@govtnz/ds/build/vue-js/H2.vue\";\nimport P from \"@govtnz/ds/build/vue-js/P.vue\";\n\nexport default { components: { \"c-alert\": Alert, \"c-h2\": H2, \"c-p\": P } };\n</script>\n",
    "vue-ts": "<template>\n  <c-alert level=\"info\" headingId=\"heading1\">\n    <c-h2 id=\"heading1\">\n      Note: Nominations are currently closed\n    </c-h2>\n    <c-p marginBottom0>\n      You can nominate a new provider at the start of the next financial year.\n    </c-p>\n  </c-alert>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport Alert from \"@govtnz/ds/build/vue-ts/Alert.vue\";\nimport H2 from \"@govtnz/ds/build/vue-ts/H2.vue\";\nimport P from \"@govtnz/ds/build/vue-ts/P.vue\";\n\nexport default { components: { \"c-alert\": Alert, \"c-h2\": H2, \"c-p\": P } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: Alert.css, H2.css, P.css\nOR in Sass (SCSS): Alert.scss, H2.scss, P.scss\n-->\n<div role=\"note\" aria-labelledby=\"heading1\">\n  <div class=\"g-alert g-alert--info\">\n    <h2 class=\"g-heading\" id=\"heading1\">\n      Note: Nominations are currently closed\n    </h2>\n    <p class=\"g-body g-body-marginBottom0\">\n      You can nominate a new provider at the start of the next financial year.\n    </p>\n  </div>\n</div>\n<div role=\"note\">\n  <div class=\"g-alert g-alert--info\">\n    <h2 class=\"g-heading\" id=\"heading1\">\n      Note: Nominations are currently closed\n    </h2>\n    <p class=\"g-body g-body-marginBottom0\">\n      You can nominate a new provider at the start of the next financial year.\n    </p>\n  </div>\n</div>\n",
    "css": "",
    "twig-embed": "{% embed \"Alert.html.twig\"  with {'level':'info', 'headingId':'heading1'} only %}{% block children %}{% embed \"H2.html.twig\"  with {'id':'heading1', 'children':'Note: Nominations are currently closed'} only %}{% endembed %}\n{% embed \"P.html.twig\"  with {'marginBottom0':'true', 'children':'You can nominate a new provider at the start of the next financial year.'} only %}{% endembed %}{% endblock %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport Alert from \"@govtnz/ds/build/mustache/Alert.mustache\";\nimport H2 from \"@govtnz/ds/build/mustache/H2.mustache\";\nimport P from \"@govtnz/ds/build/mustache/P.mustache\";\n\n// The variables Alert, H2, P are all strings that are mustache templates.\n\nexport default `${Mustache.render(Alert, {\n  mode: `live`,\n  level: `info`,\n  headingId: `heading1`,\n  children: `${Mustache.render(H2, {\n    id: `heading1`,\n    children: `Note: There are only 3 spaces remaining`\n  })}${Mustache.render(P, {\n    marginBottom0: ``,\n    children: `Complete your order to reserve your place on the course.`\n  })}`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-js/Alert\";\nimport \"@govtnz/ds/build/css/Alert.css\"; // or @govtnz/ds/build/scss/Alert.scss\nimport H2 from \"@govtnz/ds/build/react-js/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport P from \"@govtnz/ds/build/react-js/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <Alert mode=\"live\" level=\"info\" headingId=\"heading1\">\n    <H2 id=\"heading1\">Note: There are only 3 spaces remaining</H2>\n    <P marginBottom0>\n      Complete your order to reserve your place on the course.\n    </P>\n  </Alert>\n);\n",
    "react-ts": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-ts/Alert\";\nimport \"@govtnz/ds/build/css/Alert.css\"; // or @govtnz/ds/build/scss/Alert.scss\nimport H2 from \"@govtnz/ds/build/react-ts/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport P from \"@govtnz/ds/build/react-ts/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <Alert mode=\"live\" level=\"info\" headingId=\"heading1\">\n    <H2 id=\"heading1\">Note: There are only 3 spaces remaining</H2>\n    <P marginBottom0>\n      Complete your order to reserve your place on the course.\n    </P>\n  </Alert>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-js-styled-components/Alert\";\nimport H2 from \"@govtnz/ds/build/react-js-styled-components/H2\";\nimport P from \"@govtnz/ds/build/react-js-styled-components/P\";\n\nexport default () => (\n  <Alert mode=\"live\" level=\"info\" headingId=\"heading1\">\n    <H2 id=\"heading1\">Note: There are only 3 spaces remaining</H2>\n    <P marginBottom0>\n      Complete your order to reserve your place on the course.\n    </P>\n  </Alert>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-ts-styled-components/Alert\";\nimport H2 from \"@govtnz/ds/build/react-ts-styled-components/H2\";\nimport P from \"@govtnz/ds/build/react-ts-styled-components/P\";\n\nexport default () => (\n  <Alert mode=\"live\" level=\"info\" headingId=\"heading1\">\n    <H2 id=\"heading1\">Note: There are only 3 spaces remaining</H2>\n    <P marginBottom0>\n      Complete your order to reserve your place on the course.\n    </P>\n  </Alert>\n);\n",
    "vue-js": "<template>\n  <c-alert mode=\"live\" level=\"info\" headingId=\"heading1\">\n    <c-h2 id=\"heading1\">\n      Note: There are only 3 spaces remaining\n    </c-h2>\n    <c-p marginBottom0>\n      Complete your order to reserve your place on the course.\n    </c-p>\n  </c-alert>\n</template>\n<script>\nimport Vue from \"vue\";\nimport Alert from \"@govtnz/ds/build/vue-js/Alert.vue\";\nimport H2 from \"@govtnz/ds/build/vue-js/H2.vue\";\nimport P from \"@govtnz/ds/build/vue-js/P.vue\";\n\nexport default { components: { \"c-alert\": Alert, \"c-h2\": H2, \"c-p\": P } };\n</script>\n",
    "vue-ts": "<template>\n  <c-alert mode=\"live\" level=\"info\" headingId=\"heading1\">\n    <c-h2 id=\"heading1\">\n      Note: There are only 3 spaces remaining\n    </c-h2>\n    <c-p marginBottom0>\n      Complete your order to reserve your place on the course.\n    </c-p>\n  </c-alert>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport Alert from \"@govtnz/ds/build/vue-ts/Alert.vue\";\nimport H2 from \"@govtnz/ds/build/vue-ts/H2.vue\";\nimport P from \"@govtnz/ds/build/vue-ts/P.vue\";\n\nexport default { components: { \"c-alert\": Alert, \"c-h2\": H2, \"c-p\": P } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: Alert.css, H2.css, P.css\nOR in Sass (SCSS): Alert.scss, H2.scss, P.scss\n-->\n<div\n  role=\"note\"\n  aria-labelledby=\"heading1\"\n  aria-live=\"polite\"\n  aria-atomic=\"true\"\n>\n  <div class=\"g-alert g-alert--info\">\n    <h2 class=\"g-heading\" id=\"heading1\">\n      Note: There are only 3 spaces remaining\n    </h2>\n    <p class=\"g-body g-body-marginBottom0\">\n      Complete your order to reserve your place on the course.\n    </p>\n  </div>\n</div>\n<div role=\"note\">\n  <div class=\"g-alert g-alert--info\">\n    <h2 class=\"g-heading\" id=\"heading1\">\n      Note: There are only 3 spaces remaining\n    </h2>\n    <p class=\"g-body g-body-marginBottom0\">\n      Complete your order to reserve your place on the course.\n    </p>\n  </div>\n</div>\n",
    "css": "",
    "twig-embed": "{% embed \"Alert.html.twig\"  with {'mode':'live', 'level':'info', 'headingId':'heading1'} only %}{% block children %}{% embed \"H2.html.twig\"  with {'id':'heading1', 'children':'Note: There are only 3 spaces remaining'} only %}{% endembed %}\n{% embed \"P.html.twig\"  with {'marginBottom0':'true', 'children':'Complete your order to reserve your place on the course.'} only %}{% endembed %}{% endblock %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport Alert from \"@govtnz/ds/build/mustache/Alert.mustache\";\nimport H2 from \"@govtnz/ds/build/mustache/H2.mustache\";\nimport P from \"@govtnz/ds/build/mustache/P.mustache\";\n\n// The variables Alert, H2, P are all strings that are mustache templates.\n\nexport default `${Mustache.render(Alert, {\n  level: `warning`,\n  headingId: `heading2`,\n  children: `${Mustache.render(H2, {\n    id: `heading2`,\n    children: `Warning: Your subscription will expire soon`\n  })}${Mustache.render(P, {\n    marginBottom0: ``,\n    children: `You’ll need to renew your subscription to keep using the service.`\n  })}`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-js/Alert\";\nimport \"@govtnz/ds/build/css/Alert.css\"; // or @govtnz/ds/build/scss/Alert.scss\nimport H2 from \"@govtnz/ds/build/react-js/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport P from \"@govtnz/ds/build/react-js/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <Alert level=\"warning\" headingId=\"heading2\">\n    <H2 id=\"heading2\">Warning: Your subscription will expire soon</H2>\n    <P marginBottom0>\n      You’ll need to renew your subscription to keep using the service.\n    </P>\n  </Alert>\n);\n",
    "react-ts": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-ts/Alert\";\nimport \"@govtnz/ds/build/css/Alert.css\"; // or @govtnz/ds/build/scss/Alert.scss\nimport H2 from \"@govtnz/ds/build/react-ts/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport P from \"@govtnz/ds/build/react-ts/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <Alert level=\"warning\" headingId=\"heading2\">\n    <H2 id=\"heading2\">Warning: Your subscription will expire soon</H2>\n    <P marginBottom0>\n      You’ll need to renew your subscription to keep using the service.\n    </P>\n  </Alert>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-js-styled-components/Alert\";\nimport H2 from \"@govtnz/ds/build/react-js-styled-components/H2\";\nimport P from \"@govtnz/ds/build/react-js-styled-components/P\";\n\nexport default () => (\n  <Alert level=\"warning\" headingId=\"heading2\">\n    <H2 id=\"heading2\">Warning: Your subscription will expire soon</H2>\n    <P marginBottom0>\n      You’ll need to renew your subscription to keep using the service.\n    </P>\n  </Alert>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-ts-styled-components/Alert\";\nimport H2 from \"@govtnz/ds/build/react-ts-styled-components/H2\";\nimport P from \"@govtnz/ds/build/react-ts-styled-components/P\";\n\nexport default () => (\n  <Alert level=\"warning\" headingId=\"heading2\">\n    <H2 id=\"heading2\">Warning: Your subscription will expire soon</H2>\n    <P marginBottom0>\n      You’ll need to renew your subscription to keep using the service.\n    </P>\n  </Alert>\n);\n",
    "vue-js": "<template>\n  <c-alert level=\"warning\" headingId=\"heading2\">\n    <c-h2 id=\"heading2\">\n      Warning: Your subscription will expire soon\n    </c-h2>\n    <c-p marginBottom0>\n      You’ll need to renew your subscription to keep using the service.\n    </c-p>\n  </c-alert>\n</template>\n<script>\nimport Vue from \"vue\";\nimport Alert from \"@govtnz/ds/build/vue-js/Alert.vue\";\nimport H2 from \"@govtnz/ds/build/vue-js/H2.vue\";\nimport P from \"@govtnz/ds/build/vue-js/P.vue\";\n\nexport default { components: { \"c-alert\": Alert, \"c-h2\": H2, \"c-p\": P } };\n</script>\n",
    "vue-ts": "<template>\n  <c-alert level=\"warning\" headingId=\"heading2\">\n    <c-h2 id=\"heading2\">\n      Warning: Your subscription will expire soon\n    </c-h2>\n    <c-p marginBottom0>\n      You’ll need to renew your subscription to keep using the service.\n    </c-p>\n  </c-alert>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport Alert from \"@govtnz/ds/build/vue-ts/Alert.vue\";\nimport H2 from \"@govtnz/ds/build/vue-ts/H2.vue\";\nimport P from \"@govtnz/ds/build/vue-ts/P.vue\";\n\nexport default { components: { \"c-alert\": Alert, \"c-h2\": H2, \"c-p\": P } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: Alert.css, H2.css, P.css\nOR in Sass (SCSS): Alert.scss, H2.scss, P.scss\n-->\n<div role=\"note\" aria-labelledby=\"heading2\">\n  <div class=\"g-alert g-alert--warning\">\n    <h2 class=\"g-heading\" id=\"heading2\">\n      Warning: Your subscription will expire soon\n    </h2>\n    <p class=\"g-body g-body-marginBottom0\">\n      You’ll need to renew your subscription to keep using the service.\n    </p>\n  </div>\n</div>\n<div role=\"note\">\n  <div class=\"g-alert g-alert--warning\">\n    <h2 class=\"g-heading\" id=\"heading2\">\n      Warning: Your subscription will expire soon\n    </h2>\n    <p class=\"g-body g-body-marginBottom0\">\n      You’ll need to renew your subscription to keep using the service.\n    </p>\n  </div>\n</div>\n",
    "css": "",
    "twig-embed": "{% embed \"Alert.html.twig\"  with {'level':'warning', 'headingId':'heading2'} only %}{% block children %}{% embed \"H2.html.twig\"  with {'id':'heading2', 'children':'Warning: Your subscription will expire soon'} only %}{% endembed %}\n{% embed \"P.html.twig\"  with {'marginBottom0':'true', 'children':'You’ll need to renew your subscription to keep using the service.'} only %}{% endembed %}{% endblock %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport Alert from \"@govtnz/ds/build/mustache/Alert.mustache\";\nimport H2 from \"@govtnz/ds/build/mustache/H2.mustache\";\nimport P from \"@govtnz/ds/build/mustache/P.mustache\";\n\n// The variables Alert, H2, P are all strings that are mustache templates.\n\nexport default `${Mustache.render(Alert, {\n  mode: `live`,\n  level: `warning`,\n  headingId: `heading1`,\n  children: `${Mustache.render(H2, {\n    id: `heading1`,\n    children: `Warning: The option you’ve selected is for people aged 65 or over only`\n  })}${Mustache.render(P, {\n    marginBottom0: ``,\n    children: `You’ll need to provide evidence of your date of birth when you submit your application.`\n  })}`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-js/Alert\";\nimport \"@govtnz/ds/build/css/Alert.css\"; // or @govtnz/ds/build/scss/Alert.scss\nimport H2 from \"@govtnz/ds/build/react-js/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport P from \"@govtnz/ds/build/react-js/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <Alert mode=\"live\" level=\"warning\" headingId=\"heading1\">\n    <H2 id=\"heading1\">\n      Warning: The option you’ve selected is for people aged 65 or over only\n    </H2>\n    <P marginBottom0>\n      You’ll need to provide evidence of your date of birth when you submit your\n      application.\n    </P>\n  </Alert>\n);\n",
    "react-ts": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-ts/Alert\";\nimport \"@govtnz/ds/build/css/Alert.css\"; // or @govtnz/ds/build/scss/Alert.scss\nimport H2 from \"@govtnz/ds/build/react-ts/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport P from \"@govtnz/ds/build/react-ts/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <Alert mode=\"live\" level=\"warning\" headingId=\"heading1\">\n    <H2 id=\"heading1\">\n      Warning: The option you’ve selected is for people aged 65 or over only\n    </H2>\n    <P marginBottom0>\n      You’ll need to provide evidence of your date of birth when you submit your\n      application.\n    </P>\n  </Alert>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-js-styled-components/Alert\";\nimport H2 from \"@govtnz/ds/build/react-js-styled-components/H2\";\nimport P from \"@govtnz/ds/build/react-js-styled-components/P\";\n\nexport default () => (\n  <Alert mode=\"live\" level=\"warning\" headingId=\"heading1\">\n    <H2 id=\"heading1\">\n      Warning: The option you’ve selected is for people aged 65 or over only\n    </H2>\n    <P marginBottom0>\n      You’ll need to provide evidence of your date of birth when you submit your\n      application.\n    </P>\n  </Alert>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-ts-styled-components/Alert\";\nimport H2 from \"@govtnz/ds/build/react-ts-styled-components/H2\";\nimport P from \"@govtnz/ds/build/react-ts-styled-components/P\";\n\nexport default () => (\n  <Alert mode=\"live\" level=\"warning\" headingId=\"heading1\">\n    <H2 id=\"heading1\">\n      Warning: The option you’ve selected is for people aged 65 or over only\n    </H2>\n    <P marginBottom0>\n      You’ll need to provide evidence of your date of birth when you submit your\n      application.\n    </P>\n  </Alert>\n);\n",
    "vue-js": "<template>\n  <c-alert mode=\"live\" level=\"warning\" headingId=\"heading1\">\n    <c-h2 id=\"heading1\">\n      Warning: The option you’ve selected is for people aged 65 or over only\n    </c-h2>\n    <c-p marginBottom0>\n      You’ll need to provide evidence of your date of birth when you submit your\n      application.\n    </c-p>\n  </c-alert>\n</template>\n<script>\nimport Vue from \"vue\";\nimport Alert from \"@govtnz/ds/build/vue-js/Alert.vue\";\nimport H2 from \"@govtnz/ds/build/vue-js/H2.vue\";\nimport P from \"@govtnz/ds/build/vue-js/P.vue\";\n\nexport default { components: { \"c-alert\": Alert, \"c-h2\": H2, \"c-p\": P } };\n</script>\n",
    "vue-ts": "<template>\n  <c-alert mode=\"live\" level=\"warning\" headingId=\"heading1\">\n    <c-h2 id=\"heading1\">\n      Warning: The option you’ve selected is for people aged 65 or over only\n    </c-h2>\n    <c-p marginBottom0>\n      You’ll need to provide evidence of your date of birth when you submit your\n      application.\n    </c-p>\n  </c-alert>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport Alert from \"@govtnz/ds/build/vue-ts/Alert.vue\";\nimport H2 from \"@govtnz/ds/build/vue-ts/H2.vue\";\nimport P from \"@govtnz/ds/build/vue-ts/P.vue\";\n\nexport default { components: { \"c-alert\": Alert, \"c-h2\": H2, \"c-p\": P } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: Alert.css, H2.css, P.css\nOR in Sass (SCSS): Alert.scss, H2.scss, P.scss\n-->\n<div\n  role=\"note\"\n  aria-labelledby=\"heading1\"\n  aria-live=\"polite\"\n  aria-atomic=\"true\"\n>\n  <div class=\"g-alert g-alert--warning\">\n    <h2 class=\"g-heading\" id=\"heading1\">\n      Warning: The option you’ve selected is for people aged 65 or over only\n    </h2>\n    <p class=\"g-body g-body-marginBottom0\">\n      You’ll need to provide evidence of your date of birth when you submit your\n      application.\n    </p>\n  </div>\n</div>\n<div role=\"note\" aria-live=\"polite\" aria-atomic=\"true\">\n  <div class=\"g-alert g-alert--warning\">\n    <h2 class=\"g-heading\" id=\"heading1\">\n      Warning: The option you’ve selected is for people aged 65 or over only\n    </h2>\n    <p class=\"g-body g-body-marginBottom0\">\n      You’ll need to provide evidence of your date of birth when you submit your\n      application.\n    </p>\n  </div>\n</div>\n",
    "css": "",
    "twig-embed": "{% embed \"Alert.html.twig\"  with {'mode':'live', 'level':'warning', 'headingId':'heading1'} only %}{% block children %}{% embed \"H2.html.twig\"  with {'id':'heading1', 'children':'Warning: The option you’ve selected is for people aged 65 or over only'} only %}{% endembed %}\n{% embed \"P.html.twig\"  with {'marginBottom0':'true', 'children':'You’ll need to provide evidence of your date of birth when you submit your application.'} only %}{% endembed %}{% endblock %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport Alert from \"@govtnz/ds/build/mustache/Alert.mustache\";\nimport H2 from \"@govtnz/ds/build/mustache/H2.mustache\";\nimport P from \"@govtnz/ds/build/mustache/P.mustache\";\n\n// The variables Alert, H2, P are all strings that are mustache templates.\n\nexport default `${Mustache.render(Alert, {\n  level: `success`,\n  headingId: `heading3`,\n  children: `${Mustache.render(H2, {\n    id: `heading3`,\n    children: `Success: Your account has been activated`\n  })}${Mustache.render(P, {\n    marginBottom0: ``,\n    children: `You can now access all features of the service.`\n  })}`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-js/Alert\";\nimport \"@govtnz/ds/build/css/Alert.css\"; // or @govtnz/ds/build/scss/Alert.scss\nimport H2 from \"@govtnz/ds/build/react-js/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport P from \"@govtnz/ds/build/react-js/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <Alert level=\"success\" headingId=\"heading3\">\n    <H2 id=\"heading3\">Success: Your account has been activated</H2>\n    <P marginBottom0>You can now access all features of the service.</P>\n  </Alert>\n);\n",
    "react-ts": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-ts/Alert\";\nimport \"@govtnz/ds/build/css/Alert.css\"; // or @govtnz/ds/build/scss/Alert.scss\nimport H2 from \"@govtnz/ds/build/react-ts/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport P from \"@govtnz/ds/build/react-ts/P\";\nimport \"@govtnz/ds/build/css/P.css\"; // or @govtnz/ds/build/scss/P.scss\n\nexport default () => (\n  <Alert level=\"success\" headingId=\"heading3\">\n    <H2 id=\"heading3\">Success: Your account has been activated</H2>\n    <P marginBottom0>You can now access all features of the service.</P>\n  </Alert>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-js-styled-components/Alert\";\nimport H2 from \"@govtnz/ds/build/react-js-styled-components/H2\";\nimport P from \"@govtnz/ds/build/react-js-styled-components/P\";\n\nexport default () => (\n  <Alert level=\"success\" headingId=\"heading3\">\n    <H2 id=\"heading3\">Success: Your account has been activated</H2>\n    <P marginBottom0>You can now access all features of the service.</P>\n  </Alert>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-ts-styled-components/Alert\";\nimport H2 from \"@govtnz/ds/build/react-ts-styled-components/H2\";\nimport P from \"@govtnz/ds/build/react-ts-styled-components/P\";\n\nexport default () => (\n  <Alert level=\"success\" headingId=\"heading3\">\n    <H2 id=\"heading3\">Success: Your account has been activated</H2>\n    <P marginBottom0>You can now access all features of the service.</P>\n  </Alert>\n);\n",
    "vue-js": "<template>\n  <c-alert level=\"success\" headingId=\"heading3\">\n    <c-h2 id=\"heading3\">\n      Success: Your account has been activated\n    </c-h2>\n    <c-p marginBottom0>\n      You can now access all features of the service.\n    </c-p>\n  </c-alert>\n</template>\n<script>\nimport Vue from \"vue\";\nimport Alert from \"@govtnz/ds/build/vue-js/Alert.vue\";\nimport H2 from \"@govtnz/ds/build/vue-js/H2.vue\";\nimport P from \"@govtnz/ds/build/vue-js/P.vue\";\n\nexport default { components: { \"c-alert\": Alert, \"c-h2\": H2, \"c-p\": P } };\n</script>\n",
    "vue-ts": "<template>\n  <c-alert level=\"success\" headingId=\"heading3\">\n    <c-h2 id=\"heading3\">\n      Success: Your account has been activated\n    </c-h2>\n    <c-p marginBottom0>\n      You can now access all features of the service.\n    </c-p>\n  </c-alert>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport Alert from \"@govtnz/ds/build/vue-ts/Alert.vue\";\nimport H2 from \"@govtnz/ds/build/vue-ts/H2.vue\";\nimport P from \"@govtnz/ds/build/vue-ts/P.vue\";\n\nexport default { components: { \"c-alert\": Alert, \"c-h2\": H2, \"c-p\": P } };\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: Alert.css, H2.css, P.css\nOR in Sass (SCSS): Alert.scss, H2.scss, P.scss\n-->\n<div role=\"note\" aria-labelledby=\"heading3\" tabindex=\"-1\">\n  <div class=\"g-alert g-alert--success\">\n    <h2 class=\"g-heading\" id=\"heading3\">\n      Success: Your account has been activated\n    </h2>\n    <p class=\"g-body g-body-marginBottom0\">\n      You can now access all features of the service.\n    </p>\n  </div>\n</div>\n<div role=\"note\" tabindex=\"-1\">\n  <div class=\"g-alert g-alert--success\">\n    <h2 class=\"g-heading\" id=\"heading3\">\n      Success: Your account has been activated\n    </h2>\n    <p class=\"g-body g-body-marginBottom0\">\n      You can now access all features of the service.\n    </p>\n  </div>\n</div>\n",
    "css": "",
    "twig-embed": "{% embed \"Alert.html.twig\"  with {'level':'success', 'headingId':'heading3'} only %}{% block children %}{% embed \"H2.html.twig\"  with {'id':'heading3', 'children':'Success: Your account has been activated'} only %}{% endembed %}\n{% embed \"P.html.twig\"  with {'marginBottom0':'true', 'children':'You can now access all features of the service.'} only %}{% endembed %}{% endblock %}{% endembed %}",
    "angular": ""
  },
  {
    "mustache": "// Developer note: ensure your \".mustache\" files are imported as plain text. In Webpack you might use https://github.com/webpack-contrib/raw-loader\nimport Mustache from \"mustache\";\nimport Alert from \"@govtnz/ds/build/mustache/Alert.mustache\";\nimport H2 from \"@govtnz/ds/build/mustache/H2.mustache\";\nimport Ul from \"@govtnz/ds/build/mustache/Ul.mustache\";\nimport Li from \"@govtnz/ds/build/mustache/Li.mustache\";\nimport A from \"@govtnz/ds/build/mustache/A.mustache\";\n\n// The variables Alert, H2, Ul, Li, A are all strings that are mustache templates.\n\nexport default `${Mustache.render(Alert, {\n  level: `error`,\n  headingId: `heading4`,\n  children: `${Mustache.render(H2, {\n    id: `heading4`,\n    children: `Error: There’s a problem with the following responses`\n  })}${Mustache.render(Ul, {\n    spacing: true,\n    marginBottom0: ``,\n    children: `${Mustache.render(Li, {\n      children: `${Mustache.render(A, {\n        href: `#form`,\n        children: `First name must not be empty`\n      })}`\n    })}${Mustache.render(Li, {\n      children: `${Mustache.render(A, {\n        href: `#form`,\n        children: `Last name must only include letters a to z, hyphens, spaces and apostrophes`\n      })}`\n    })}${Mustache.render(Li, {\n      children: `${Mustache.render(A, {\n        href: `#form`,\n        children: `Hours worked a week must be between 16 and 99`\n      })}`\n    })}`\n  })}`\n})}`;\n",
    "silverstripe-components": "",
    "react-js": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-js/Alert\";\nimport \"@govtnz/ds/build/css/Alert.css\"; // or @govtnz/ds/build/scss/Alert.scss\nimport H2 from \"@govtnz/ds/build/react-js/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport Ul from \"@govtnz/ds/build/react-js/Ul\";\nimport \"@govtnz/ds/build/css/Ul.css\"; // or @govtnz/ds/build/scss/Ul.scss\nimport Li from \"@govtnz/ds/build/react-js/Li\";\nimport \"@govtnz/ds/build/css/Li.css\"; // or @govtnz/ds/build/scss/Li.scss\nimport A from \"@govtnz/ds/build/react-js/A\";\nimport \"@govtnz/ds/build/css/A.css\"; // or @govtnz/ds/build/scss/A.scss\n\nexport default () => (\n  <Alert level=\"error\" headingId=\"heading4\">\n    <H2 id=\"heading4\">Error: There’s a problem with the following responses</H2>\n    <Ul spacing marginBottom0>\n      <Li>\n        <A href=\"#form\">First name must not be empty</A>\n      </Li>\n      <Li>\n        <A href=\"#form\">\n          Last name must only include letters a to z, hyphens, spaces and\n          apostrophes\n        </A>\n      </Li>\n      <Li>\n        <A href=\"#form\">Hours worked a week must be between 16 and 99</A>\n      </Li>\n    </Ul>\n  </Alert>\n);\n",
    "react-ts": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-ts/Alert\";\nimport \"@govtnz/ds/build/css/Alert.css\"; // or @govtnz/ds/build/scss/Alert.scss\nimport H2 from \"@govtnz/ds/build/react-ts/H2\";\nimport \"@govtnz/ds/build/css/H2.css\"; // or @govtnz/ds/build/scss/H2.scss\nimport Ul from \"@govtnz/ds/build/react-ts/Ul\";\nimport \"@govtnz/ds/build/css/Ul.css\"; // or @govtnz/ds/build/scss/Ul.scss\nimport Li from \"@govtnz/ds/build/react-ts/Li\";\nimport \"@govtnz/ds/build/css/Li.css\"; // or @govtnz/ds/build/scss/Li.scss\nimport A from \"@govtnz/ds/build/react-ts/A\";\nimport \"@govtnz/ds/build/css/A.css\"; // or @govtnz/ds/build/scss/A.scss\n\nexport default () => (\n  <Alert level=\"error\" headingId=\"heading4\">\n    <H2 id=\"heading4\">Error: There’s a problem with the following responses</H2>\n    <Ul spacing marginBottom0>\n      <Li>\n        <A href=\"#form\">First name must not be empty</A>\n      </Li>\n      <Li>\n        <A href=\"#form\">\n          Last name must only include letters a to z, hyphens, spaces and\n          apostrophes\n        </A>\n      </Li>\n      <Li>\n        <A href=\"#form\">Hours worked a week must be between 16 and 99</A>\n      </Li>\n    </Ul>\n  </Alert>\n);\n",
    "react-js-styled-components": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-js-styled-components/Alert\";\nimport H2 from \"@govtnz/ds/build/react-js-styled-components/H2\";\nimport Ul from \"@govtnz/ds/build/react-js-styled-components/Ul\";\nimport Li from \"@govtnz/ds/build/react-js-styled-components/Li\";\nimport A from \"@govtnz/ds/build/react-js-styled-components/A\";\n\nexport default () => (\n  <Alert level=\"error\" headingId=\"heading4\">\n    <H2 id=\"heading4\">Error: There’s a problem with the following responses</H2>\n    <Ul spacing marginBottom0>\n      <Li>\n        <A href=\"#form\">First name must not be empty</A>\n      </Li>\n      <Li>\n        <A href=\"#form\">\n          Last name must only include letters a to z, hyphens, spaces and\n          apostrophes\n        </A>\n      </Li>\n      <Li>\n        <A href=\"#form\">Hours worked a week must be between 16 and 99</A>\n      </Li>\n    </Ul>\n  </Alert>\n);\n",
    "react-ts-styled-components": "import React from \"react\";\nimport Alert from \"@govtnz/ds/build/react-ts-styled-components/Alert\";\nimport H2 from \"@govtnz/ds/build/react-ts-styled-components/H2\";\nimport Ul from \"@govtnz/ds/build/react-ts-styled-components/Ul\";\nimport Li from \"@govtnz/ds/build/react-ts-styled-components/Li\";\nimport A from \"@govtnz/ds/build/react-ts-styled-components/A\";\n\nexport default () => (\n  <Alert level=\"error\" headingId=\"heading4\">\n    <H2 id=\"heading4\">Error: There’s a problem with the following responses</H2>\n    <Ul spacing marginBottom0>\n      <Li>\n        <A href=\"#form\">First name must not be empty</A>\n      </Li>\n      <Li>\n        <A href=\"#form\">\n          Last name must only include letters a to z, hyphens, spaces and\n          apostrophes\n        </A>\n      </Li>\n      <Li>\n        <A href=\"#form\">Hours worked a week must be between 16 and 99</A>\n      </Li>\n    </Ul>\n  </Alert>\n);\n",
    "vue-js": "<template>\n  <c-alert level=\"error\" headingId=\"heading4\">\n    <c-h2 id=\"heading4\">\n      Error: There’s a problem with the following responses\n    </c-h2>\n    <c-ul spacing marginBottom0>\n      <c-li>\n        <c-a href=\"#form\">\n          First name must not be empty\n        </c-a>\n      </c-li>\n      <c-li>\n        <c-a href=\"#form\">\n          Last name must only include letters a to z, hyphens, spaces and\n          apostrophes\n        </c-a>\n      </c-li>\n      <c-li>\n        <c-a href=\"#form\">\n          Hours worked a week must be between 16 and 99\n        </c-a>\n      </c-li>\n    </c-ul>\n  </c-alert>\n</template>\n<script>\nimport Vue from \"vue\";\nimport Alert from \"@govtnz/ds/build/vue-js/Alert.vue\";\nimport H2 from \"@govtnz/ds/build/vue-js/H2.vue\";\nimport Ul from \"@govtnz/ds/build/vue-js/Ul.vue\";\nimport Li from \"@govtnz/ds/build/vue-js/Li.vue\";\nimport A from \"@govtnz/ds/build/vue-js/A.vue\";\n\nexport default {\n  components: { \"c-alert\": Alert, \"c-h2\": H2, \"c-ul\": Ul, \"c-li\": Li, \"c-a\": A }\n};\n</script>\n",
    "vue-ts": "<template>\n  <c-alert level=\"error\" headingId=\"heading4\">\n    <c-h2 id=\"heading4\">\n      Error: There’s a problem with the following responses\n    </c-h2>\n    <c-ul spacing marginBottom0>\n      <c-li>\n        <c-a href=\"#form\">\n          First name must not be empty\n        </c-a>\n      </c-li>\n      <c-li>\n        <c-a href=\"#form\">\n          Last name must only include letters a to z, hyphens, spaces and\n          apostrophes\n        </c-a>\n      </c-li>\n      <c-li>\n        <c-a href=\"#form\">\n          Hours worked a week must be between 16 and 99\n        </c-a>\n      </c-li>\n    </c-ul>\n  </c-alert>\n</template>\n<script lang=\"ts\">\nimport Vue from \"vue\";\nimport Alert from \"@govtnz/ds/build/vue-ts/Alert.vue\";\nimport H2 from \"@govtnz/ds/build/vue-ts/H2.vue\";\nimport Ul from \"@govtnz/ds/build/vue-ts/Ul.vue\";\nimport Li from \"@govtnz/ds/build/vue-ts/Li.vue\";\nimport A from \"@govtnz/ds/build/vue-ts/A.vue\";\n\nexport default {\n  components: { \"c-alert\": Alert, \"c-h2\": H2, \"c-ul\": Ul, \"c-li\": Li, \"c-a\": A }\n};\n</script>\n",
    "scss": "",
    "html": "<!--\nRemember to add these styles:\nin CSS: Alert.css, H2.css, Ul.css, Li.css, A.css\nOR in Sass (SCSS): Alert.scss, H2.scss, Ul.scss, Li.scss, A.scss\n-->\n<div role=\"note\" aria-labelledby=\"heading4\" tabindex=\"-1\">\n  <div class=\"g-alert g-alert--error\">\n    <h2 class=\"g-heading\" id=\"heading4\">\n      Error: There’s a problem with the following responses\n    </h2>\n    <ul class=\"g-ul g-ul--spacing g-ul--mb-0\">\n      <li><a class=\"g-link\" href=\"#form\"> First name must not be empty </a></li>\n      <li>\n        <a class=\"g-link\" href=\"#form\">\n          Last name must only include letters a to z, hyphens, spaces and\n          apostrophes\n        </a>\n      </li>\n      <li>\n        <a class=\"g-link\" href=\"#form\">\n          Hours worked a week must be between 16 and 99\n        </a>\n      </li>\n    </ul>\n  </div>\n</div>\n<div role=\"note\" tabindex=\"-1\">\n  <div class=\"g-alert g-alert--error\">\n    <h2 class=\"g-heading\" id=\"heading4\">\n      Error: There’s a problem with the following responses\n    </h2>\n    <ul class=\"g-ul g-ul--spacing g-ul--mb-0\">\n      <li><a class=\"g-link\" href=\"#form\"> First name must not be empty </a></li>\n      <li>\n        <a class=\"g-link\" href=\"#form\">\n          Last name must only include letters a to z, hyphens, spaces and\n          apostrophes\n        </a>\n      </li>\n      <li>\n        <a class=\"g-link\" href=\"#form\">\n          Hours worked a week must be between 16 and 99\n        </a>\n      </li>\n    </ul>\n  </div>\n</div>\n",
    "css": "",
    "twig-embed": "{% embed \"Alert.html.twig\"  with {'level':'error', 'headingId':'heading4'} only %}{% block children %}{% embed \"H2.html.twig\"  with {'id':'heading4', 'children':'Error: There’s a problem with the following responses'} only %}{% endembed %}\n{% embed \"Ul.html.twig\"  with {'spacing':'true', 'marginBottom0':'true'} only %}{% block children %}{% embed \"Li.html.twig\" %}{% block children %}{% embed \"A.html.twig\"  with {'href':'#form', 'children':'First name must not be empty'} only %}{% endembed %}{% endblock %}{% endembed %}\n{% embed \"Li.html.twig\" %}{% block children %}{% embed \"A.html.twig\"  with {'href':'#form', 'children':'Last name must only include letters a to z, hyphens, spaces and apostrophes'} only %}{% endembed %}{% endblock %}{% endembed %}\n{% embed \"Li.html.twig\" %}{% block children %}{% embed \"A.html.twig\"  with {'href':'#form', 'children':'Hours worked a week must be between 16 and 99'} only %}{% endembed %}{% endblock %}{% endembed %}{% endblock %}{% endembed %}{% endblock %}{% endembed %}",
    "angular": ""
  }
]